// Header used on interior pages
.page-header {
  padding-top: 24px;
  text-align: center;
  background-color: $accentPrimary;
  // Order used for override
  // scss-lint:disable DeclarationOrder
  @include schemeColor(background-color, base);

  .page-title {
    @include fontHead;
    padding-bottom: 23px;
    font-size: 40px;
    line-height: 1.23;
    color: $neutralWhite;
  }

  // <nav> wrapping a <ul>
  .page-navigation {
    padding-bottom: 40px;

    ul {
      @include listHorizontal;
    }

    li {
      + li {
        margin-left: 3.25%;
      }

      a {
        // NB: Not using labelPrimary on this singleton element
        @include labelPrototype;
        display: block;
        padding-bottom: 5px;
        font-size: 10px;
        color: $neutralWhite;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        transition: border-color $duration $timing;

        &:hover,
        &.active {
          border-color: $neutralWhite;
          opacity: 1;
        }
      }
    }
  }
}

.info-pop {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: left;

  .badge {
    @include schemeColor(background-color, base);
    @include iconPre($info);
    display: inline-block;
    padding: 10px;
    font-size: 9px;
    color: $neutralWhite;
    text-transform: uppercase;
    cursor: pointer;

    &:hover ~ .description-container {
      max-height: 400px;
    }

    &::before {
      padding-right: 4px;
      font-size: 16px;
      vertical-align: bottom;
    }
  }

  .description-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height $durationSlow $timing;

    &:hover {
      max-height: 400px;
    }

    .description {
      padding: 2.165%;
      font-size: 12px;

      @include respond($break40) {
        width: 100%;
        max-width: 410px;
      }
    }
  }
}

.media-list {
  padding-top: 39px;
  padding-bottom: 30px;
  margin-right: -25px;
  margin-left: -25px;

  .media-item {
    display: inline-block;
    width: 240px;
    padding: 0 25px 45px;
    margin-right: -4px;
    vertical-align: top;

    > a {
      display: block;
      text-decoration: none;

      &:hover .arrow-link {
        color: $accentSecondary;
      }
    }

    .inner {
      margin: 0 auto;
      text-align: left;
    }

    .arrow-link {
      color: $neutralOffBlack;
      text-align: left;
    }

    .media-info {
      img {
        max-width: 190px;
        height: 215px;
      }

      .media-thumbnail-placeholder {
        width: 190px;
        height: 215px;
        background-color: $neutralGray20;
      }

      .title, .subtitle {
        @include copyTertiary;
      }

      .title, .subtitle .dark {
        color: $neutralOffBlack;
      }

      .title {
        padding-top: 10px;
        padding-bottom: 19px;
      }

      .subtitle {
        padding-bottom: 19px;
      }

      .arrow-link {
        margin-top: 28px;
      }
    }
  }

  .media-modal {
    display: none;

    &.open {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      overflow: scroll;
      background: $translucentWhite;
    }

    .inner {
      position: relative;
      width: 90%;
      margin: 80px auto;
      background-color: $neutralGray20;

      @include respond($break30) {
        width: 70%;
      }

      @include respond($break70) {
        width: 50%;
      }

      header {
        @include schemeColor(background-color, base);
        position: relative;
        padding: 58px 61px 38px;
        color: $neutralWhite;
      }

      .inner-bottom {
        padding: 40px 61px 48px;
      }

      .close {
        @include buttonUnstyled;
        @include iconPost($x);
        @include labelPrimary;
        position: absolute;
        top: 20px;
        right: 20px;
        display: block;
        padding: 0;
        color: $neutralWhite;
        text-decoration: none;
        cursor: pointer;

        &::after {
          padding-left: 12px;
          font-size: 13px;
          vertical-align: top;
        }
      }

      .title {
        @include fontHead;
        font-size: 27px;
        font-weight: $semibold;
      }

      .author {
        @include copySecondary;
        padding-top: 25px;
      }

      .field-title {
        @include labelPrimary;
        padding-bottom: 8px;
      }

      .field-content {
        @include copySecondary;
        padding-bottom: 22px;
        color: $neutralOffBlack;
      }

      .media-fields {
        padding-bottom: 30px;
        margin-right: 0;
        margin-left: 0;

        @include respond($break30) {
          margin-right: -20px;
          margin-left: -20px;
        }
      }

      .media-field {
        display: inline-block;
        padding-right: 0;
        padding-left: 0;
        margin-right: -4px;

        @include respond($break30) {
          width: 50%;
          padding-right: 20px;
          padding-left: 20px;
        }

        .field-title {
          @include labelPrimary;
          padding-bottom: 8px;
        }

        .field-content {
          @include copySecondary;
          padding-bottom: 22px;
          color: $neutralOffBlack;
        }
      }
    }
  }
}

.media-callout-list {
  text-align: center;

  ul {
    @include listUnstyled;
    margin: 8px -12px 80px;

    li {
      display: inline-block;
      max-width: 49%;
      height: 100%;
      padding: 22px 12px 0;
    }
  }

  img {
    display: block;
    width: 100%;
  }

  .section-header {
    padding: 50px 60px 40px;
  }

  .extended {
    padding-bottom: 80px;
  }

  .label {
    @include labelPrototype;
    display: block;
    font-size: 0.7em;
  }

  // scss-lint:disable DeclarationOrder
  @include respond($break30) {
    img {
      display: block;
      width: auto;
      height: 215px;
    }
  }
}

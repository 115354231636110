.navigation-blocks {
  padding: 122px 0 78px;

  .grid {
    @include grid(2, 25px);
    padding-left: 0;

    .col-interior {
      padding: 42px;
      background-color: $neutralGray10;
      border-top: 12px solid $accentPrimary;
    }
  }

  .section-header {
    padding-top: 0;
    padding-right: 0;
  }

  .arrow-link-list a {
    color: $neutralOffBlack;
    border-bottom-color: $neutralGray50;

    &::after {
      color: $neutralGray50;
    }
  }
}

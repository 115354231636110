//padded to work inside container
.navigation-arrow-grid {
  padding: 32px 0 50px;

  .scheme-base &, .scheme-dark & {
    .arrow-link-list a {
      color: $neutralWhite;
      border-bottom-color: $neutralWhite;

      &::after {
        color: $neutralWhite;
      }

      &:hover {
        color: $neutralGray20;
        border-bottom-color: $neutralGray30;

        &::after {
          color: $neutralGray30;
        }
      }
    }
  }

  .grid {
    @include grid(2, 120px, 0);
  }
}

//padded to work inside container
.navigation-arrow-tabs,
.tab-panels {
  padding: 40px 50px 65px;
}

.tab-panels {
  > .tab .section-header {
    padding-top: 20px;
  }

  @each $_, $context in $spectraContexts {
    .scheme-#{$context} + .scheme-#{$context} & {
      padding-top: 0;
    }
  }

  .scheme-none + .scheme-none & {
    padding-top: 0;
  }
}

.navigation-arrow-tabs {
  .grid {
    @include grid(2, 120px, 0);
  }

  .arrow-link-list a {
    color: $neutralOffBlack;
    border-bottom-color: $neutralGray50;

    &::after {
      color: $neutralGray50;
    }

    &:hover, &.open {
      color: initial;
      border-color: $accentPrimary;

      &::after {
        color: $accentPrimary;
        content: $arrowLeft;
      }
    }

    .scheme-base &, .scheme-dark & {
      color: $neutralGray40;
      border-bottom-color: $neutralGray40;

      &::after {
        color: $neutralGray40;
      }

      &:hover, &.open {
        color: $neutralWhite;
        border-bottom-color: $neutralWhite;

        &::after {
          color: $neutralWhite;
        }
      }
    }
  }
}

.tab {
  display: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height $durationSlow $timing;

  &.open {
    display: block;
  }

  //.title {
  //  @include fontHead;
  //  padding-bottom: 30px;
  //  font-size: 32px;
  //  line-height: 1.281;
  //  color: $neutralOffBlack;
  //}

  .section-header {
    padding: {
      top: 30px;
      bottom: 30px;
    }
  }

  .content-grid-newspaper-layout {
    padding: 0;
  }

  > .callout-grid {
    > .row:first-child {
      padding-top: 50px;
    }
  }

  .rte-content p {
    color: $neutralGray70;
  }
}

.tab-section {
  padding: 38px 0;
}

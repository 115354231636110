// Component Structure
// -------------------

// Structure-only classes, with parameters for margins, padding, position, display, etc.

// They may contain:
// Nested classes/elements that have structural parameters only

// NB: Containers that are modular regardless of context should use EM units
// Structure should not be dependent on typography styles but font-size CAN be used as a structural style if necessary

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms


// Imports
// --------------------------------------------------------
@import 'grid';


// Utilities
// --------------------------------------------------------
.rel {
  position: relative;
}

.align-center {
  text-align: center;
}

.hide-desktop {
  @include respond($break60) {
    display: none;
  }
}

// Layout
// --------------------------------------------------------
@mixin containerPrototype {
  max-width: $containerWidth;
  padding-right: $padding30;
  padding-left: $padding30;
  margin: 0 auto;

  @include respond($break100) {
    padding-right: $padding100;
    padding-left: $padding100;
  }
};

.container {
  @include containerPrototype;
}

@mixin fullOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Very simple content container for content areas that don't have
// other classes
.content-container {
  padding-bottom: 67px;
}

// Another very simple container for areas where we need some empty space (e.g. /404)
.content-empty {
  padding-top: 175px;
  padding-bottom: 175px;
}

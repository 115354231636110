.search-overlay {
  @include fullOverlay;
  padding-top: 120px;
  visibility: hidden;
  background-color: $neutralGray10;
  opacity: 0;
  transition: opacity $duration $timing, visibility $duration $timing;

  @include respond($break30) {
    padding-top: 30.27vw;
  }

  @include respond($break70) {
    padding-top: 280px;
  }

  &.open {
    visibility: visible;
    opacity: 1;
    // Don't transition visibility on open to avoid markup change race conditions
    transition: opacity $duration $timing;
  }

  .close-flush {
    position: absolute;
    top: 0;
    right: $padding30;

    @include respond($break100) {
      right: 40px;
    }
  }
}


.search-form {
  text-align: center;

  input[type='text'] {
    @include fontHead;
    width: 100%;
    padding-bottom: 35px;
    font-size: 30px;
    color: $neutralOffBlack;
    text-align: center;
    letter-spacing: 0.02em;
    background: transparent;
    border: 0;
    outline: 0;

    @include respond($break60) {
      padding-bottom: 60px;
      font-size: 5.287vw;
    }

    @include respond($break100) {
      font-size: 49px;
    }
  }

  input[type='submit'] {
    @include buttonUnstyled;
    @include labelPrototype;
    display: inline-block;
    font-size: 11px;
  }
}

.search-header {
  padding: 61px 0;
  background-color: $neutralGray10;

  .num-results {
    @include labelPrototype;
    font-size: 13px;
    text-align: center;

    .num {
      color: $accentPrimary;
    }

    .caption {
      color: $neutralOffBlack;
    }
  }

  .search-form {
    input[type='text'] {
      padding-top: 32px;
      padding-bottom: 15px;
    }
  }
}

.results-list {
  &.narrow {
    max-width: 650px;
    margin: 0 auto;
  }

  > ul {
    @include listUnstyled;
    padding-bottom: 72px;

    > li + li {
      padding-top: 40px;
    }
  }

  .title {
    @include fontHead;
    display: block;
    padding-bottom: 6px;
    font-size: 18px;
    color: $accentPrimaryDark;
    text-decoration: none;
  }

  .highlight {
    color: $neutralGray90;
  }

  .summary {
    font-size: 13px;
    color: $neutralGray60;
  }

  .pager {
    text-align: center;

    ul {
      display: inline-block;
    }

    li {
      // padding-top: 0;

      a {
        text-decoration: none;
      }
    }
  }
}

.media-search {
  margin-top: 32px;
}

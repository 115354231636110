// Component Appearance
// --------------------

// Appearance classes contain primarily texture parameters: background-color, transparency, borders, etc. as well
// as some minor, internal structural properties where abstraction isn't required (to prevent over-abstracting)

// They may contain:
// Nested classes/elements with other structural or textural properties

// Appearance blocks should not contain typographic classes nor be dependent on the font-size from the element, unless
// it is as a non-dependent, comprehensive mixin (for now), or if the font size is being used for structural reasons (like the size of an icon-font glyph)

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms


// Utilities
// --------------------------------------------------------
// Mixin to apply spectra colors conditionally to an element based on a parent class
// Refer to color scheme map in variables for more

// Example
// Markup:
//  <div class="scheme-s3">
//    <div class="block">Example</div>
//  </div>
//
// SCSS:
// .block {
//    // This would be a default color used in the case of no wrapper class
//    background-color: $accentPrimary;
//
//    // This would overwrite the background color with the base variant of
//    // the colorscheme that is set in the wrapper class (in this case: "s3")
//    @include schemeColor(background-color, base);
//  }

@mixin schemeColor($parameter, $variant) {
  @each $name, $scheme in $spectra {
    .scheme-#{$name} & {
      // Disable linter for default sass function
      // scss-lint:disable NameFormat
      #{$parameter}: map_get($scheme, $variant);
    }
  }
}


// Layout
// --------------------------------------------------------
// Generate background classes (i.e. .scheme-light) for each
// variant in a colorscheme.
$schemeVariants: (base, light, medium, dark);
@each $name in $schemeVariants {
  .scheme-#{$name} {
    @include schemeColor(background-color, $name);

    .rte-content {
      @if $name == base or $name == dark {
        @include schemeColor(color, light);
      } @else {
        @include schemeColor(color, base);
      }
    }
  }
}

.scheme-gray {
  background-color: $neutralGray10;
}

.scheme-lead {
  background-color: $neutralGray20;
}


// Links/Buttons
// --------------------------------------------------------
.rte-content a {
  color: inherit;
  text-decoration: underline;
  transition: opacity $duration $timing;

  &:hover {
    opacity: 0.75;
  }
}

// This is an image-grid with floaty-slidey-colored-box text overlays
// Default is full sized
// Can be ul or a div
// This grid was originally built on @mixin grid, and was converted to @mixin flexGrid, see below.

@use "sass:math";

@mixin flexGrid($count, $padding, $rowPadding: $padding, $type: "full") {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $padding;
  row-gap: $rowPadding;
  justify-content: center;

  overflow: hidden;
  font-size: 0;
  // If used on a <ul>
  @include listUnstyled;

  .col {
    display: flex;
    align-items: stretch;
    font-size: $typeReset;
    min-inline-size: 0;

    flex-basis: math.div(939px, $count) - $padding;
    flex-grow: 0;
    flex-shrink: 1;
  }

  a {
    display: block;
    inline-size: 100%;
  }

  figure {
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    flex-direction: column;
  }

  img,
  .img-placeholder {
    min-block-size: 64%;
  }

  .img-placeholder {
    background: $neutralGray30;
    block-size: 100%;
  }

  figcaption {
    @include labelPrototype;
    color: $neutralWhite;
    text-align: left;
    flex-grow: 1;
  }

  a {
    text-decoration: none;

    // Darken background colors for 1 color image grids
    &:hover {
      opacity: 1;

      figcaption {
        @include schemeColor(background-color, dark);

        .subcaption {
          @include schemeColor(color, light);
        }
      }
    }
  }

  @if $type == "full" {
    figcaption {
      min-block-size: 36%;
      padding: 18px 20px;
      font-size: 15px;
    }
  } @else if $type == "mini" {
    figcaption {
      min-block-size: 48%;
      padding: 11px 13px;
      font-size: 13px;
    }
  }
}

// GRID - FULL

.image-grid-programs-full,
.image-grid-subprograms {
  padding-bottom: 86px;

  .grid {
    @include flexGrid(3, 15px, 20px);
  }

  figure {
    position: relative;
    .completed {
      @extend .block-tag;
    }
  }
}

// GRID - MINI

.image-grid-programs-mini {
  padding-bottom: 86px;

  .grid {
    @include flexGrid(4, 15px, 20px);
    margin-bottom: 15px;

    .col {
      // Allow for multiple color schemes in nested list
      @each $name, $scheme in $spectra {
        &.scheme-#{$name} {
          figcaption {
            // Disable linter for default sass function
            // scss-lint:disable NameFormat
            background-color: map_get($scheme, base);
          }

          // Darken background colors for multi-color image grids
          a:hover figcaption {
            // Disable linter for default sass function
            // scss-lint:disable NameFormat
            background-color: map_get($scheme, dark);
          }
        }
      }
    }
  }

  // Can be <li> or <a>
  .full-bar {
    @include labelPrototype;
    padding: 10px 15px;
    font-size: 13px;
    color: $neutralWhite;
    text-decoration: none;
    background-color: $neutralGray100;

    &:hover {
      color: $neutralOffBlack;
      opacity: 1;
    }
  }
}

// Database components that are used on multiple database pages
// Local variables
$databaseFieldBorder: 3px solid $neutralGray40;

.database-form-wrapper {
  @include clearfix;
  position: relative;
}

.data-list-form-wrapper {
  @include clearfix;

  &:last-child {
    padding-bottom: 40px;
  }
}

.database-description {
  padding: 48px 0 57px;
  color: $neutralGray70;
  background-color: $neutralGray20;

  .scheme-s0 & {
    background-color: $neutralGray10;
  }
}

.database-container {
  @include clearfix;
  max-width: $containerWidth;
  margin: 0 auto;
}

.database {
  float: left;
  width: 73%;
  padding: 60px 50px 70px 40px;

  // Order used for override
  // scss-lint:disable DeclarationOrder
  @include respond($break60, max, width) {
    width: 100%;
    padding: 60px 50px 70px calc(40px + 4.885%);
  }

  .data-list-form-wrapper & {
    width: 100%;
    padding: 40px 50px 20px;
  }
}

.database-header {
  @include fontHead;
  padding: 12px 0 42px;
  font-size: 23px;
  line-height: 1.2;
  color: $accentPrimary;
  border-top: 3px solid $accentPrimary;

  h2 {
    font-size: 23px;
    line-height: 1.2;
  }

  .data-list-form-wrapper & {
    border-top: 0;
  }
}

.database-controls {
  float: left;
  width: 27%;
  padding: 60px 3.906% 60px 4.883%;
  // In case controls happen to be longer than results
  margin-bottom: 70px;
  background-color: $neutralGray10;

  .field-group {
    header {
      @include labelPrototype;
      padding: 7px 0 13px;
      font-size: 14px;
      color: $neutralGray90;
      border-top: 3px solid $neutralGray40;
    }

    ul {
      @include listUnstyled;
      font-size: 12px;

      li {
        margin: 0 0 11px;
      }
    }

    + .field-group {
      padding-top: 44px;
    }

    .extended {
      max-height: 0;
      margin-top: 11px;
      overflow: hidden;
      transition: max-height $duration $timing;

      &.nested {
        .checkbox {
          margin-left: 13px;
        }

        .checkbox:last-child {
          margin-bottom: 13px;
        }
      }
    }

    .caret-down-link {
      transition: margin-top $duration $timing;

      .open-text {
        display: none;
      }

      &::after {
        transition: transform $duration $timing;
      }

      &.open {
        margin-top: 15px;

        .closed-text {
          display: none;
        }

        .open-text {
          display: inherit;
        }

        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .checkbox {
    display: block;

    + .checkbox {
      margin-top: 11px;
    }
  }

  .checkbox-category {
    position: relative;

    // Overwrite .sub-nav-button colors and link-hovers with
    // color schemes
    @each $name, $scheme in $spectra {
      &.scheme-#{$name} {
        .nested-toggle {
          // Using standard sass function and map for variable property
          // scss-lint:disable VariableForProperty, NameFormat
          color: map_get($scheme, base);
        }
      }
    }
  }

  .nested-toggle {
    @include iconPre($plus);
    @include iconPost($minus);
    position: absolute;
    top: 2px;
    right: -10px;
    display: block;
    width: 11px;
    height: 11px;
    font-size: 10px;
    text-align: center;
    cursor: pointer;

    &::after {
      display: none;
    }

    &.open {
      &::before {
        display: none;
      }

      &::after {
        display: inherit;
      }
    }
  }

  .sidebar-toggle {
    @include fontIcon;
    position: absolute;
    top: 0;
    left: 100%;
    width: 13.72%;
    height: 100%;
    padding: 0;
    color: $neutralGray70;
    text-decoration: none;
    background-color: $neutralGray10;
    border: 0;
    transition: color $duration $timing, background-color $duration $timing;
    appearance: none;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: $neutralWhite;
      background-color: $neutralGray40;
      opacity: 1;
    }

    &::after {
      position: absolute;
      top: 75px;
      right: 13px;
      content: $caretRight;
    }

    &.open::after {
      content: $caretLeft;
    }

    @include respond($break60, min, width) {
      display: none;
    }
  }

  // Order used for override
  // scss-lint:disable DeclarationOrder
  @include respond($break60, max, width) {
    $mobileSidebarWidth: 250px;
    position: absolute;
    left: -$mobileSidebarWidth;
    width: $mobileSidebarWidth;
    height: 100%;
    padding-right: 0;
    transition: left $durationSlow $timing;

    &.open {
      left: 0;
    }

    .field-groups {
      max-height: 98%;
      overflow-y: scroll;
    }
  }
}

.database-search, .media-search {
  position: relative;
  border-top: $databaseFieldBorder;

  .search-input {
    width: 100%;

    input {
      width: 100%;
      padding: 9px 12px 9px 30px;
      font-size: 14px;
      color: $neutralGray70;
      border: 0;
      outline: none;

      &:focus + .icon-magnify {
        color: $accentSecondary;
      }
    }

    .icon-magnify {
      @include iconPre($magnify);
      position: absolute;
      top: 9px;
      left: 0;
      font-size: 14px;
      color: $accentPrimary;
    }
  }

  .results-count {
    font-size: 14px;
    color: $neutralGray60;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    white-space: nowrap;

    span {
      color: $accentPrimary;
    }

    // Override
    // scss-lint:disable DeclarationOrder
    @include respond($break40, max, width) {
      display: none;
    }
  }
}

.database-pagination {
  @include clearfix;

  .pager {
    float: right;
  }
}

.media-pagination,
.news-pagination,
.search-pagination {
  padding-bottom: 116px;
  text-align: center;

  .pager {
    display: inline-block;
  }
}

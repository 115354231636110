.database-fellows {
  padding: 33px 0 28px;

  // Table widths
  .first-name {
    float: left;
    width: 16%;
    padding-right: 12px;
  }

  .last-name {
    float: left;
    width: 18%;
    padding-right: 12px;
  }

  .university {
    float: left;
    width: 36%;
    padding-right: 12px;

    .data-list-form-wrapper & {
      width: 30%;
    }
  }

  .field {
    float: left;
    width: 20%;
    padding-right: 12px;
  }

  //.year {
  //  float: left;
  //  width: 10%;
  //}

  .list-header {
    @include clearfix;
    @include labelPrototype;
    padding-bottom: 6px;
    font-size: 11px;
    text-align: left;
    border-bottom: 1px solid $neutralGray30;

    //.year::after {
    //  @include fontIcon;
    //  display: inline-block;
    //  padding: 3px 0 0 4px;
    //  font-size: 6px;
    //  vertical-align: top;
    //  content: $caretDown;
    //}
  }

  .data-list {
    @include listUnstyled;

    > li {
      @include clearfix;
      position: relative;
      padding: 19px 0 10px;
      margin: -1px 0 0;
      font-size: 12px;
      line-height: 1.2;
      border-bottom: 1px solid $neutralGray30;
    }
  }

  // Override
  // scss-lint:disable DeclarationOrder
  @include respond($break60, max, width) {
    padding-top: 0;

    .list-header {
      display: none;
    }

    .first-name, .last-name, .university, .field, .year {
      display: block;
      float: none;
      width: 100% !important;
      padding-right: 0;
      padding-bottom: 6px;
      color: $neutralOffBlack;

      &.last-name, &.first-name {
        display: inline-block;
        width: auto;
      }

      span {
        @include labelPrototype;
        font-size: 11px;
        color: $neutralGray70;
      }
    }
  }
}

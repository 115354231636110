// Content
// -------

// Elements that live in RTE content
// --------------------------------------------------------
.rte-content {
  .scheme-base &, .scheme-dark & {
    color: $neutralWhite;
  }

  ul li, ol li {
    position: relative;
    padding-left: 21px;
    font-size: 15px;
    line-height: 1.467;
  }

  // Default RTE list style
  ul {
    @include listUnstyled;

    li::before {
      position: absolute;
      top: 6px;
      left: 2px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      border: 1px solid $neutralGray20;
      border-radius: 100%;

      .scheme-base & {
        @include schemeColor(border-color, dark);
      }

      .scheme-dark & {
        border-color: $neutralOffBlack;
      }
    }

    li::after {
      position: absolute;
      top: 8px;
      left: 0;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      border: 1px solid $neutralOffBlack;
      border-radius: 100%;

      .scheme-base &, .scheme-dark & {
        border-color: $neutralWhite;
      }

      .scheme-light &, .scheme-medium & {
        @include schemeColor(border-color, base);
      }
    }
  }

  ol {
    @include listUnstyled;
    counter-reset: ol-counter;

    li::before {
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 11px;
      content: counter(ol-counter);
      counter-increment: ol-counter;
    }
  }

  // NOTE: Not sure if this is for all content, or just two column
  // elements.
  p {
    font-size: 15px;
  }

  // RTE Content scoped overrides
  .data-detail-description & {
    p, ul, ol {
      font-size: 14px;
    }
  }

  .database-grants & {
    p, ul, ol {
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .content-grid-newspaper-layout & {
    p {
      margin-top: 0;
      margin-bottom: 1.4em;
    }

    ul, ol {
      li {
        margin-top: 0;
        margin-bottom: 22px;
      }
    }
  }

  //styles for default RTE table
  table {
    td, th {
      padding: 17px 0 10px;
      text-align: left;
      border-bottom: 1px solid $neutralGray30;
    }

    td + td, th + th {
      padding-left: 16px;
    }

    th {
      font-weight: $regular;
      text-transform: uppercase;
    }
  }

  .arrow-link {
    text-decoration: none;
  }
}

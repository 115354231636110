// Component Typography and Prototypes
// -----------------------------------

// Template typography classes and mixins/prototypes that can be extended into content classes.
// Typographic classes can contain some structural (not textural) design where it does not require abstraction,
// and textural classes dealing with typography (color, text-decoration, pseudo elements that modify text, etc).

// They may contain:
// Nested classes or elements with typographic/basic structural modifications only, for instance a header
// with <span> classes inside.

// These should not contain classes/prototypes to handle icon fonts. Those should be handles in a separate icons partial.

// Contents
// --------------------------
// - Utilities
// - Prototypes
// - Copy
// - Headings
// - Subheadings
// - Signage
// - Labels
// - Lists
// - Forms

// Copy
// --------------------------------------------------------
@mixin copyPrimary {
  font-size: 17px;
}

@mixin copySecondary {
  font-size: 15px;
}

@mixin copyTertiary {
  font-size: 13px;
}

// Headings
// --------------------------------------------------------

@mixin headingPrimary {
  @include fontHead;
  font-size: 27px;
  font-weight: $semibold;

  @include respond($break60) {
    font-size: 32px;
  }
}

// Labels
// --------------------------------------------------------

@mixin labelPrototype {
  font-weight: $regular;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@mixin labelPrimary {
  @include labelPrototype;
  font-size: 10px;
}

@mixin captionPrimary {
  @include labelPrototype;
  font-size: 7px;
}

// This is only used in this context so far,
// but the styles can be abstracted if this grid needs to be used in a wider context
.image-grid-staff {
  // Can be ul or a div
  padding-bottom: 54px;

  .grid {
    @include grid(4, 15px);
    a {
      inline-size: 100%;
    }
    .col {
      display: flex;
    }
  }

  figure {
    inline-size: 100%;
    block-size: 100%;
    display: flex;
    flex-direction: column;
  }

  .background-image {
    padding-top: 52.25%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  figcaption {
    @include fontHead;
    inline-size: 100%;
    flex-grow: 1;
    padding: 11px 14px;
    font-size: 18px;
    font-weight: $semibold;
    color: $neutralOffBlack;
    background-color: $neutralGray20;
    transition: color $duration $timing, background-color $duration $timing;

    .subcaption {
      @include fontBody;
      @include labelPrototype;
      @include schemeColor(color, base);
      padding-top: 3px;
      font-size: 9px;
      font-weight: $regular;
      transition: color $duration $timing;
    }
  }

  a {
    display: block;
    text-decoration: none;

    &:hover {
      opacity: 1;

      figcaption {
        @include schemeColor(background-color, base);
        color: $neutralWhite;

        .subcaption {
          @include schemeColor(color, light);
        }
      }
    }
  }
}

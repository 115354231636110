/* Overrides to oc-gdpr-plugin cookie consent banner */
.gdpr-banner {
  color: $neutralOffBlack;
  background-color: $neutralGray10;
  box-shadow: 0px 0px 15px 0px rgba($neutralBlack, 0.2);
  width: 100%;

  &__button {
    text-decoration: none;
    box-shadow: none;

    &--opt-out {
      background-color: $neutralGray40;
    }

    &--opt-in {
      color: $neutralGray40;
      background-color: $accentPrimary;
    }
  }
}

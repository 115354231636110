.hamburger-overlay {
  @include fullOverlay;
  padding-top: 110px;
  visibility: hidden;
  background-color: $neutralGray10;
  opacity: 0;
  transition: opacity $duration $timing, visibility $duration $timing;

  &.open {
    visibility: visible;
    opacity: 1;
    // Don't transition visibility on open to avoid markup change race conditions
    transition: opacity $duration $timing;
  }

  .close-flush {
    position: absolute;
    top: 0;
    left: $padding30;

    @include respond($break100) {
      left: 40px;
    }
  }
}

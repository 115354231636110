.database-grants {
  padding: 33px 0 28px;

  // Table widths
  .grantee {
    float: left;
    width: 50%;
    padding-right: 12px;
  }

  .amount {
    float: left;
    width: 17%;
    padding-right: 12px;
  }

  .city {
    float: left;
    width: 22%;
    padding-right: 12px;
  }

  //.year {
  //  float: left;
  //  width: 11%;
  //}

  .permalink {
    @include labelPrototype;
    float: right;
    font-size: 11px;
    color: $neutralGray70;
    text-decoration: underline;
  }

  .list-header {
    @include labelPrototype;
    padding-bottom: 6px;
    font-size: 11px;
    text-align: left;
    border-bottom: 1px solid $neutralGray30;
  }

  .data-list {
    @include listUnstyled;

    > li {
      position: relative;
      padding: 19px 0 0;
      margin: -1px 0 0;

      header {
        @include clearfix;
        padding-bottom: 10px;
        font-size: 12px;
        line-height: 1.2;
        color: $neutralGray70;

        .grantee {
          font-size: 13px;
          color: $neutralGray90;
        }
      }

      .summary {
        @include clearfix;
        padding-bottom: 18px;
        border-bottom: 1px solid $neutralGray40;

        p {
          font-size: 12px;
          color: $neutralGray70;
        }
      }

      .caret-down-link {
        display: block;
        margin-top: 15px;
        color: $neutralGray70;
      }
    }
  }

  .details {
    max-height: 0;
    padding: 0 25px;
    margin: 0 -25px;
    overflow: hidden;
    background-color: $neutralGray10;
    transition: max-height $durationSlow $timing;

    &.open {
      &::before {
        background-color: $neutralGray30;
      }

      + .summary {
        display: none;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: -25px;
      left: -25px;
      z-index: -1;
      display: block;
      height: 100%;
      content: '';
      transition: background-color $duration $timing;
    }

    .grid {
      @include grid(2, 23px);
      padding: 30px 0 45px;
    }

    // Also grid .cols
    ul {
      @include listUnstyled;
    }

    li, p {
      font-size: 12px;
      color: $neutralGray70;

      span {
        @include labelPrototype;
        display: block;
        margin-bottom: 5px;
        font-size: 10px;
        color: $neutralGray70;
      }

      + li {
        margin-top: 19px;
      }
    }

    .brief-description {
      padding-top: 30px;
      line-height: 1.3em;
    }

    footer {
      @include clearfix;
      padding-bottom: 16px;
    }

    .caret-down-link {
      &::after {
        transform: rotate(180deg);
      }

      &:hover::after {
        transform: rotate(180deg) translateY(4px);
      }
    }
  }

  .description {
    padding-bottom: 40px;
    // Columnarize RTE content
    .rte-content {
      @include respond($break70) {
        columns: 2;
        column-gap: 40px;
      }
    }
  }

  .brief-description {
    padding-top: 10px;
  }

  // Override
  // scss-lint:disable DeclarationOrder
  @include respond($break60, max, width) {
    padding-top: 0;

    .list-header {
      display: none;
    }

    .grantee, .amount, .city, .year {
      display: block;
      width: 100%;
      padding-right: 0;
      padding-bottom: 6px;
      color: $neutralOffBlack;

      span {
        @include labelPrototype;
        font-size: 11px;
        color: $neutralGray70;
      }
    }

    .data-list > li .caret-down-link {
      display: none;
    }
  }
}

//Special override to adapt the existing mobile sidebar to this slightly wider variant
.database-controls.grants {
  @include respond($break60, max, width) {
    $mobileSidebarWidthGrants: 250px;
    position: absolute;
    left: -$mobileSidebarWidthGrants;
    width: $mobileSidebarWidthGrants;
    padding-right: 10px;
    transition: left $durationSlow $timing;

    &.open {
      left: 0;
    }
  }
}

.data-detail-header {
  padding: 48px 0 38px;
  background-color: $neutralGray20;

  .title {
    @include fontHead;
    font-size: 26px;
    color: $neutralOffBlack;
  }

  p {
    margin-top: 27px;
    font-size: 14px;
    line-height: 1.571;
  }

  .staff-detail & {
    position: relative;
    padding: 0;
    background-color: $neutralWhite;
  }

  .staff-portrait {
    position: relative;
    z-index: 10;
    max-width: 100%;
    height: auto;
  }

  .staff-ribbon {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 62px;
    content: '';
    background-color: $neutralGray10;
  }
}

.data-detail-subheader {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $neutralGray10;

  .title {
    @include headingPrimary;
    color: $neutralOffBlack;
  }

  .position {
    padding-top: 7px;
    font-weight: $regular;
  }
}

.data-detail-meta {
  padding: 36px 0 37px;
  background-color: $neutralGray10;

  .staff-detail & {
    background-color: $neutralWhite;
  }

  .container {

    ul {
      @include listUnstyled;
    }

    .grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 80px;

      // All Columns (including Grant Detail Columns)
      .col {
        flex-basis: 0;
        flex-grow: 1;
        min-inline-size: 300px;

        font-size: 14px;
        line-height: 1.571;
        color: $neutralGray70;

        display: flex;
        flex-direction: column;
        gap: 19px;

        .label {
          @include labelPrototype;
          font-size: 10px;
          color: $neutralGray70;
          display: block;
          margin-block-end: 6px;
        }
      }

      // Staff Detail Columns Only
      // Staff Bio
      .bio {
        flex-basis: 0;
        flex-grow: 999;
        min-inline-size: 50%;

        gap: 0;

        font-size: 16px;
      }

      // Staff Info
      .info {
        flex-basis: 220px;
        flex-grow: 1;

        .label {
          font-size: 15px;
          color: $neutralBlack;
        }

        > ul {
          display: flex;
          flex-direction: column;
          gap: 19px;

          > li {
            border-block-start: solid 3px $neutralGray30;
            padding-block-start: 10px;
            display: flex;
            flex-direction: column;
            gap: 14px;

            .label {
              @include copyTertiary;
              padding-bottom: 0;
              margin-bottom: 0;
              font-size: 13px;
              color: $neutralBlack;
            }

            .subordinate {
              color: $neutralBlack;
            }
          }
        }
      }
    }
  }
}

.data-detail-description {
  padding: 38px 0 63px;
  color: $neutralGray70;

  // Columnarize RTE content
  .rte-content {
    @include respond($break70) {
      columns: 2;
      column-gap: 80px;

      &.single-col {
        columns: 1;
      }
    }
  }

  .arrow-link-left {
    display: block;
    margin-top: 56px;
    color: $neutralOffBlack;
  }
}
.callout-with-image {
  .grid {
    padding: 70px 0;

    .col {
      display: block;
      width: 197px;
      padding-right: 25px;
      padding-left: 25px;
      margin: 0 auto;
      vertical-align: top;

      + .col {
        width: 100%;
        margin-top: 25px;
      }
    }
  }

  .callout-image {
    width: 100%;
  }

  .section-header {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 9px;
  }

  p {
    color: $neutralGray60;
  }

  .arrow-link {
    display: inline-block;
    margin-top: 35px;
    color: $neutralGray90;
  }

  // Override
  // scss-lint:disable DeclarationOrder
  @include respond($break40) {
    .grid {
      padding: 70px 25px;

      .col {
        display: inline-block;
        width: 197px;
        margin-right: -4px;

        + .col {
          width: calc(100% - 197px);
          margin-top: 0;
        }
      }

    }
  }
}

// Base mixins
// ---------

// Clearfix
// $mode accepts 'light' or 'heavy'
// 'light' is sufficient for clearing floats, while 'heavy' is required to
// prevent margin collapsing
// --------------------------
@mixin clearfix($mode: light) {
  @if $mode == heavy {
    &::before,
    &::after {
      display: table;
      content: ' ';
    }

    &::after {
      clear: both;
    }
  } @else {
    &::after {
      display: block;
      clear: both;
      content: ' ';
    }
  }
}

.clearfix {
  @include clearfix;
}

// REM use
// --------------------------------------------------------
// NB: Uncomment to mark usage in a project
// Takes the properties for which the unit is used, and the desired rem amount(s)
// Returns pixel values (for lt-ie9) and overrides with rem values for supported browsers
// Example: rem(padding, 20, 10, 35, 15);
// NB: Property must come first in mixin declaration
// Utilizes the variable: $baseFontSize
//@mixin rem($property, $values...) {
//  $max: length($values);
//  $pxValues: '';
//  $remValues: '';
//
//  @for $i from 1 through $max {
//    $value: nth($values, $i);
//    $pxValues: #{$pxValues + $value * $baseFontSize}px;
//
//    @if $i < $max {
//      $pxValues: #{$pxValues + " "};
//    }
//  }
//
//  @for $i from 1 through $max {
//    $value: nth($values, $i);
//    $remValues: #{$remValues + $value}rem;
//
//    @if $i < $max {
//      $remValues: #{$remValues + " "};
//    }
//  }
//
//  #{$property}: $pxValues;
//  #{$property}: $remValues;
//}

// Kill ye FOUT
// Depends on Typekit's webfontloader adding the .wf-active class...
// --------------------------------------------------------
@mixin killFout {
  visibility: hidden;

  // scss-lint:disable SelectorFormat
  .wf-active &, // Show when fonts become active
  .no-js &, // Show if no javascript
  .mceContentBody & { // Show if this is the RTE ;)
    visibility: visible;
  }
}

@mixin thinGlyphs {
  // Ignore spelling and prefixes for pre-standards parameter
  // scss-lint:disable PropertySpelling
  font-smooth: always;
  // scss-lint:disable VendorPrefix
  -webkit-font-smoothing: antialiased;
  // scss-lint:disable VendorPrefix
  -moz-osx-font-smoothing: grayscale;
}

// Full bleed
// $direction accepts 'up', 'down' or 'none';
// NB using 'up' or 'down' causes the shadow to
// extend to the top or bottom of the viewport
// Only use 'none' on relatively wide elements,
// and set $width to the width of the element
// --------------------------
@mixin fullBleed($color, $direction: none, $width: 400em) {
  @if $direction == none {
    box-shadow: -$width 0 0 0 $color, $width 0 0 0 $color, (-$width * 2) 0 0 0 $color, ($width * 2) 0 0 0 $color;
  }

  @if $direction == down {
    box-shadow: 0 $width 0 $width $color;
  }

  @if $direction == up {
    box-shadow: 0 (-$width) 0 $width $color;
  }
  background-color: $color;
}

// Lists
// --------------------------------------------------------
// Floated list
// $direction accepts 'right' or 'left'
// --------------------------
@mixin listFloated($space, $direction: left) {
  @include clearfix;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;

  li {
    float: $direction;

    + li {
      @if $direction == left {
        margin-left: $space;
      } @else {
        margin-right: $space;
      }
    }
  }
}

// Unstyled List
// --------------------------
@mixin listUnstyled {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

// Horizontal List
// --------------------------
@mixin listHorizontal {
  @include listUnstyled;

  li {
    display: inline-block;
  }
}

// Links, Buttons
// --------------------------------------------------------
@mixin buttonUnstyled {
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
}

// Prevents user from accidentally selecting text
// on click, helpful for labels
@mixin noSelect {
  // Ignore vendor prefixing as this is a pre-standards parameter
  // scss-lint:disable VendorPrefix
  user-select: none;
  // scss-lint:disable VendorPrefix
  user-select: none;
  // scss-lint:disable VendorPrefix
  user-select: none;
}

// Fonts
// -----

// Do your @font-face embedding here, in the privacy of your own partial
@font-face {
  font-family: "GT America Standard";
  src: url("../fonts/GT-America-Standard-Regular.woff2") format("woff2"),
    url("../fonts/GT-America-Standard-Regular.woff") format("woff"),
    url("../fonts/GT-America-Standard-Regular.ttf") format("truetype"); /* Safari, Android, iOS */

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "GT America Standard";
  src: url("../fonts/GT-America-Standard-Light.woff2") format("woff2"),
    url("../fonts/GT-America-Standard-Light.woff") format("woff"),
    url("../fonts/GT-America-Standard-Light.ttf") format("truetype"); /* Safari, Android, iOS */

  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "GT Super Text";
  src: url("../fonts/GT-Super-Text-Regular.woff2") format("woff2"),
    url("../fonts/GT-Super-Text-Regular.woff") format("woff"),
    url("../fonts/GT-Super-Text-Regular.ttf") format("truetype"); /* Safari, Android, iOS */

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "GT Super Text";
  src: url("../fonts/GT-Super-Text-Bold.woff2") format("woff2"),
    url("../fonts/GT-Super-Text-Bold.woff") format("woff"),
    url("../fonts/GT-Super-Text-Bold.ttf") format("truetype"); /* Safari, Android, iOS */

  font-style: normal;
  font-weight: 600;
}

// Mixins
// --------------------------
@mixin fontBody {
  font-family: "GT America Standard", Arial, "Helvetica Neue", Helvetica,
    sans-serif;
}

@mixin fontHead {
  font-family: "GT Super Text", Georgia, Times, "Times New Roman", serif;
  font-weight: $regular;
}

.disclaimer {
  position: relative;
  max-width: 800px;
  min-height: 88px;
  padding: 20px 40px;
  margin: 0 auto 63px;
  font-size: 17px;
  color: $accentPrimary;
  text-align: center;
  text-transform: uppercase;
  background-color: $neutralWhite;
  border: 2px solid $accentPrimary;
}

// Z-Stack
// -------

// Z-Indeces for all top level components, all in one place, all in order

.header-fixed {
  z-index: 100;
}

.hamburger-overlay, .search-overlay {
  z-index: 300;
}

@include respond($break60, max, width) {
  .database-controls {
    z-index: 99;
  }
}

.media-modal {
  z-index: 400;
}

.report-thumbnail-single {
  margin-top: 35px;

  a:hover {
    .arrow-link::after {
      right: 0;
    }
  }

  figure {
    position: relative;

    &::before {
      display: inline-block;
      width: 141px;
      height: 194px;
      margin: 5px 0 0 5px;
      color: $neutralGray50;
      content: '';
      background-image: url('../images/icon-pages.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 136px;
      height: 188px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  .arrow-link {
    display: inline-block;
    margin-top: 32px;
    text-decoration: none;
  }
}

.reports-thumbnail-list, .reports-list {
  ul {
    @include listUnstyled;
  }

  li {
    display: inline-block;
    width: 75px;
    margin-right: 69px;
    margin-bottom: 19px;
  }
}

.reports-thumbnail-list {
  padding-bottom: 53px;

  figure {
    @include iconPre($pages);
    position: relative;

    &::before {
      display: inline-block;
      width: 62px;
      height: 85px;
      margin: 2px 0 0 2px;
      color: $neutralGray50;
      content: '';
      background-image: url('../images/icon-pages.png');
      background-repeat: no-repeat;
      background-size: contain;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 83px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
  }

  a {
    display: block;
  }
}

.reports-list {
  a {
    display: block;
    text-decoration: underline;
  }
}


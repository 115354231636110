.events-list {
  & > .container {
    padding-block-start: 30px;
    padding-block-end: 110px;
  }

  .filters-block {
    border-top: 3px solid $neutralGray40;
    margin-block-end: 44px;
    padding-block-start: 6px;

    .results-count {
      display: flex;
      justify-content: flex-end;
      gap: 6px;
      @include labelPrototype;
      font-size: 12px;
      line-height: 17px;
      color: $neutralGray50;

      .highlight {
        color: $neutralOffBlack;
      }
    }

    .pagination {
      display: flex;
      justify-content: space-between;
      margin-block-start: 44px;

      .per-page {
        display: flex;
        gap: 16px;
        align-items: center;

        .select-label {
          flex-grow: 0;

          &.hide-mobile {
            @include respond($break40, max) {
              display: none;
            }
          }
        }
      }
    }
  }

  .pager {
    margin-block-start: 72px;
    display: flex;
    justify-content: center;
  }

  .cards {
    @include listUnstyled;

    & > li + li {
      margin-block-start: 50px;
    }

    .event-card {
      .card-body {
        padding-inline: 36px;
        padding-block-start: 32px;
        padding-block-end: 26px;
        background-color: $neutralGray20;

        .title-block {
          display: flex;
          justify-content: space-between;
          color: $accentPrimary;
          margin-block-end: 25px;
          gap: 20px;

          @include respond($break40, max) {
            flex-direction: column-reverse;
            align-items: center;
            gap: 12px;
          }

          .title {
            @include fontHead;
            font-size: 27px;
            line-height: 1.23;

            & > * {
              @include fontHead;
              color: $accentPrimary;
              font-size: 27px;
              line-height: 1.23;
            }
          }

          .date {
            display: inline-block;
            text-transform: uppercase;
            margin-block-end: 16px;
            font-size: 15px;
            line-height: 21px;
          }
        }

        .image-block {
          height: auto;
          text-align: right;

          img {
            height: 140px;
          }
          
          .image-credit {
            @include captionPrimary;
            color: $neutralGray70;
          }
        }

        .links-block {
          display: flex;
          justify-content: space-between;
          text-transform: uppercase;
          @include labelPrototype;
          font-size: 12px;
          line-height: 17px;
          color: $neutralOffBlack;

          .caret-down-link {
            display: block;
            font-size: 12px;
            color: inherit;

            &::after {
              margin-top: 5px;
              margin-left: 5px;
            }

            &.open {
              &::after {
                transform: rotate(180deg);
              }

              &:hover::after {
                transform: rotate(180deg) translateY(4px);
              }

              span {
                display: none;
              }
              span.close {
                display: inline;
              }
            }

            span {
              display: inline;
            }
            span.close {
              display: none;
            }
          }

          .permalink {
            @include labelPrototype;
            font-size: 12px;
            line-height: 17px;
            color: $neutralOffBlack;
            text-decoration: none;

            &::after {
              margin-left: 5px;
              margin-top: -6px;
              font-size: 12px;
            }
          }
        }

        .details {
          max-height: 0;
          overflow: hidden;
          transition: max-height $durationSlow $timing;

          &.open {
            &::before {
              background-color: $neutralGray30;
            }
          }

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            display: block;
            height: 100%;
            content: "";
            transition: background-color $duration $timing;
          }
        }

        .grid {
          display: grid;
          grid-template-columns: repeat(2, minmax(auto, 255px));
          grid-template-rows: repeat(3, max-content);
          padding-block-start: 36px;
          margin-block-end: 25px;
          column-gap: 24px;
          row-gap: 28px;

          & > li.description {
            grid-column: 1 / span 2;
          }

          &.grid-2 {
            grid-template-rows: repeat(2, max-content);
          }
        }

        ul {
          @include listUnstyled;
        }

        .rte-content p + ul,
        .rte-content p + ol {
          padding-top: 6px;
        }

        li,
        p {
          font-size: 15px;
          color: $neutralOffBlack;

          span {
            @include labelPrototype;
            display: block;
            margin-bottom: 5px;
            font-size: 12px;
            color: $neutralGray70;
          }
        }
      }

      .card-footer {
        padding-block: 6px;
        padding-inline: 30px;
        display: flex;
        gap: 24px;

        @include schemeColor(background-color, base);

        .category {
          color: $neutralWhite;
          width: max-content;
          @include labelPrototype;
          font-size: 12px;
          height: 48px;
          display: flex;
          gap: 12px;
          align-items: center;

          svg {
            width: auto;
            height: 48px;
          }

          .category-name {
            display: inline-block;

            @include respond($break40, max) {
              display: none;
            }

            &.Special-Initiatives,
            &.Film,
            &.Radio {
              margin-left: -8px;
            }
          }
        }
      }
    }
  }
}

.upcoming-events-block {
  padding-block-start: 44px;
  padding-block-end: 72px;

  @include schemeColor(background-color, medium);

  .block-header {
    margin: auto;
    text-align: center;

    > h2 {
      @include fontHead;
      color: $accentPrimary;
      font-size: 32px;
    }

    > p {
      font-size: 17px;
      line-height: 24px;
      margin-block-start: 16px;

      @include schemeColor(color, base);
    }
  }

  .events {
    margin-block-start: 35px;
    margin-inline: auto;
    text-align: center;

    .events-label {
      display: block;
      @include labelPrototype;
      font-size: 12px;
      line-height: 17px;
      margin-block-end: 28px;
    }

    .event-images-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 26px;
      justify-content: center;
      margin-block-end: 44px;

      img {
        height: 200px;
      }

      @include respond($break40, max) {
        flex-direction: column;

        img {
          height: 200px;
          align-self: center;
        }
      }
    }
  }
}

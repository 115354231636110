// NB: Site map makes heavy use of em units so that
// it can be sized relative to where it is used
// sizes are relative to 16px (base font size)

.site-map {
  @include clearfix;
  padding-left: 16px;
  margin-left: -1.875em;

  @include respond($break30) {
    margin-left: -3.75em;
  }

  @include respond($break60) {
    height: auto;
    padding-left: 0;
    margin-left: -6.25em;
    overflow: hidden;
  }

  .hamburger-overlay & {
    height: 75vh;
    overflow: auto;
  }

  .col {
    float: left;
    max-width: 50%;
    padding-left: 1.875em;

    @include respond($break30) {
      padding-left: 3.75em;
    }

    @include respond($break60) {
      max-width: 33.333%;
      padding-left: 6.25em;
    }

    &:last-child {
      margin-top: 2.188em;
      clear: both;

      @include respond($break60) {
        margin-top: 0;
        clear: none;
      }
    }
  }

  .list-heading {
    @include fontHead;
    font-size: 1.375em;
    line-height: 1.209;
    color: $accentPrimary;
    display: block;

    a {
      color: $accentPrimary;
      text-decoration: none;

      &:hover {
        color: $accentSecondary;
        opacity: 1;
      }
    }

    + .list-heading {
      // 10px (22px font-size)
      margin-top: 0.455em;
    }
  }

  ul {
    @include listUnstyled;
    margin-top: 0.938em;

    + .list-heading {
      // 60px (22px font-size)
      margin-top: 2.727em;
    }

    li {
      position: relative;

      + li {
        margin-top: 0.75em;
      }

      // <button>
      // NB uses pixel measurements and does not change with scope
      .sub-nav-button {
        @include buttonUnstyled;
        @include iconPre($plus);
        position: absolute;
        top: -1px;
        left: -22px;
        padding: 0;

        &::before {
          font-size: 9px;
        }

        &.open::before {
          content: $minus;
        }
      }

      a {
        font-size: 0.875em;
        color: $accentPrimary;
        text-decoration: none;

        &:hover {
          color: $accentSecondary;
          opacity: 1;
        }
      }

      // Overwrite .sub-nav-button colors and link-hovers with
      // color schemes
      @each $name, $scheme in $spectra {
        &.scheme-#{$name} {
          .sub-nav-button {
            // Using standard sass function and map for variable property
            // scss-lint:disable VariableForProperty, NameFormat
            color: map_get($scheme, base);
          }

          a:hover {
            // scss-lint:disable VariableForProperty, NameFormat
            color: $accentSecondary;
          }
        }
      }
    }

    // Nested lists, hidden by default
    ul {
      display: block;
      max-height: 0;
      padding-left: 1.2em;
      margin: 0;
      overflow: hidden;
      transition: max-height $duration $timing;

      li:first-child {
        margin-top: 13px;
      }
    }
  }

  .arrow-link {
    display: inline-block;
    // 72px (12px font-size)
    margin-top: 6em;
    color: $neutralOffBlack;

    &:hover {
      color: $accentSecondary;
    }
  }
}

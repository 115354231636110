.navigation-two-column {
  padding-bottom: 53px;
  color: $neutralGray40;
  background-color: $accentPrimary;

  .grid {
    @include grid(2, 62px);
    padding-left: 0;
  }

  .section-header {
    max-width: 540px;
    padding-top: 66px;
    padding-right: 0;
    padding-bottom: 63px;

    h2 {
      color: $neutralWhite;
    }
  }

  figure img {
    width: 100%;
  }

  .arrow-link-list a {
    color: $neutralWhite;
    border-bottom-color: $neutralWhite;

    &::after {
      color: $neutralWhite;
    }

    &:first-child {
      margin-top: 4px;
    }
  }
}

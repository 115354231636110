.site-footer {
  padding-top: 64px;
  background-color: $neutralGray10;

  .footer-grid {
    @include clearfix;

    .col-third {
      @include respond($break70) {
        float: left;
        width: 33.333%;
      }
    }

    .col-two-thirds {
      @include respond($break70) {
        float: left;
        width: 66.666%;
      }
    }
  }

  .logo-type-left {
    svg {
      width: calc(100% - 50px);
      max-width: 217.25px;
      height: auto;
      color: $accentPrimary;
    }
  }

  .site-map {
    // Down size entire site-map relatively
    font-size: 0.914em;
  }

  .colophon {
    @include clearfix;
    @include labelPrototype;
    padding-top: 29px;
    padding-bottom: 28px;
    margin-top: 38px;
    font-size: 12px;
    color: $neutralGray40;
    text-align: right;
    background-color: $accentPrimary;
  }

  // <nav> with a label and nested <ul>
  .external-links {
    float: left;
    color: $neutralWhite;

    ul {
      @include listHorizontal;
      display: inline-block;
      margin-left: 6px;
    }

    li {
      + li {
        margin-left: 5px;
      }

      a {
        color: $neutralGray30;
        text-decoration: none;

        &:hover {
          color: $accentSecondary;
          opacity: 1;
        }
      }
    }
  }
}

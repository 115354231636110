.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 0;
  text-align: center;
  visibility: hidden;
  background-color: $accentPrimary;
  opacity: 0;
  transition: opacity $duration $timing, visibility $duration $timing;

  &.headroom--not-top {
    visibility: visible;
    opacity: 1;
  }

  .container {
    position: relative;
  }

  .logo-type {
    padding-top: 23px;
    padding-bottom: 27px;

    svg {
      display: inline-block;
      width: 42.11vw;
      max-width: 200px;
      margin-top: -1px;

      @include respond($break30) {
        max-width: 348px;
        margin-top: -2px;
      }
    }
  }

  .header-hamburger, .header-magnify {
    top: 50%;
    transform: translateY(-50%);
  }

  .page-title {
    @include fontHead;
    max-width: 70%;
    padding-top: 23px;
    padding-bottom: 27px;
    margin: 0px auto;
    font-size: 20px;
    color: $neutralWhite;
  }

  // <nav> wrapping a <ul>
  .page-navigation {
    @include schemeColor(background-color, dark);
    padding-top: 5px;

    ul {
      @include listHorizontal;
      @include containerPrototype;
    }

    li {
      + li {
        margin-left: 3.7%;
      }

      a {
        @include labelPrototype;
        display: block;
        padding-bottom: 5px;
        font-size: 10px;
        color: $neutralWhite;
        text-decoration: none;
        border-bottom: 3px solid transparent;
        transition: border-color $duration $timing;

        &:hover,
        &.active {
          border-color: $neutralWhite;
          opacity: 1;
        }
      }
    }
  }
}

// Unicode range values!
// Order is alphabetical.
// Icons are stored in a SASS map, so adding one automatically generates
// a placeholder for using it (see below)
// --------------------------------------------------------
$arrowLeft: '\f101';
$arrowRight: '\f102';
$arrowUp: '\f103';
$caretDown: '\f104';
$caretLeft: '\f105';
$caretRight: '\f106';
$caretUp: '\f107';
$check: '\f108';
$hamburger: '\f109';
$info: '\f10a';
$magnify: '\f10b';
$minus: '\f10c';
$minusShort: '\f10d';
$pages: '\f10e';
$plus: '\f10f';
$x: '\f110';


// Icon font
// --------------------------------------------------------
// This font was generated using https://www.npmjs.com/package/webfonts-generator
// and plain old CIC ingenuity
@font-face {
  font-family: 'sloan-icons';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/sloan-icons.woff') format('woff')
}

@mixin fontIcon {
  font-family: 'sloan-icons', sans-serif;
}

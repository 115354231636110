.hero {
  @include containerPrototype;
  text-align: center;

  // Heroes have 66px overlap banner below them
  .hero-ribbon {
    position: absolute;
    left: 0;
    display: block;
    width: 100%;
    height: 66px;
    content: '';
    background-color: $accentPrimary;

    section.first-ribbon + & {
      display: none;
    }

    // Override
    // scss-lint:disable DeclarationOrder
    @include schemeColor(background-color, base);
  }

  figure {
    position: relative;
    display: inline-block;

    .completed {
      @extend .block-tag;
    }
  }

  .stacked {
    position: relative;
    height: 0;
    padding-top: 60%;
    background-color: $neutralWhite;

    figure {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: 50% 50%;
      background-size: cover;
      opacity: 0;
      transition: opacity 0.5s $timing;

      &.active {
        opacity: 1;
      }
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  figcaption {
    @include captionPrimary;
    position: absolute;
    right: 13px;
    bottom: 8px;
    color: $neutralWhite;
    text-align: right;
  }
}

.hero-leads {
  position: relative;
  height: 123px;
  // Note that this background color can be abstracted as
  // a modifier class if variants are needed
  background-color: $accentPrimaryDark;

  > .container {
    position: relative;
    height: 100%;
  }

  .pagination {
    position: absolute;
    right: $padding30;
    // Relative z-index
    z-index: 1;
    width: 22px;
    height: 100%;
    margin-right: 13px;
    color: $neutralGray70;

    @include respond($break100) {
      right: $padding100;
    }

    &::before {
      position: relative;
      bottom: 0;
      display: inline-block;
      width: 0;
      height: 100%;
      margin-left: -5px;
      font-size: 0;
      vertical-align: middle;
      content: '';
    }
  }

  .pagination-ordinal {
    @include labelPrototype;
    display: inline-block;
    width: 100%;
    font-size: 10px;
    text-align: center;
    vertical-align: middle;
  }

  .pagination-next, .pagination-prev {
    @include buttonUnstyled;
    position: absolute;
    right: 0;
    display: block;
    padding: 0;
    font-size: 14px;
  }

  .pagination-next {
    @include iconPost($arrowRight);
    top: 16px;
  }

  .pagination-prev {
    @include iconPre($arrowLeft);
    bottom: 16px;
  }
}

.hero-lead {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s $timing, visibility 0.5s $timing;

  &.active {
    visibility: visible;
    opacity: 1;
    transition: opacity 0s, visibility 0s;
  }

  .lead-link {
    position: relative;
    display: block;
    min-height: 125px;
    padding: 24px 65px 24px 22px;
    color: $neutralOffBlack;
    background-color: $neutralGray10;
    transition: background-color $duration $timing, color $duration $timing;

    a {
      text-decoration: none;

      &:hover {
        opacity: 1;
      }
    }
  }

  .lead-line {
    color: $neutralOffBlack;
  }

  .lead-tag {
    @include schemeColor(background-color, base);
    position: absolute;
    bottom: 100%;
    left: 0;
    padding: 7px 21px 8px;
    font-size: 15px;
    color: $neutralWhite;
  }

  .lead-heading {
    @include fontHead;
    font-size: 23px;

    span {
      display: block;
      max-width: 100%;

      + span {
        margin-top: 6px;
      }
    }
  }

  .meta {
    @include labelPrototype;
    padding-top: 17px;
    font-size: 10px;
    color: $neutralGray70;
  }
}

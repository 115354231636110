// Shared Components
// -----------------

// Component classes that contain structure, typography, and appearance declarations either from
// mixins or singletons.

// These are smaller components that are shared across multiple larger components, with either no
// or scopable variations in those placements.

// Contents
// --------------------------
// - Imports
// - Prototypes
// - Utilities
// - Layout
// - Type
// - Images
// - Article Lists:
// - Navigation Lists
// - Buttons/Links
// - Tables
// - Forms

// Layout
// --------------------------------------------------------
.grid-ribbon,
.disclaimer-ribbon {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 62px;
  content: '';

  section.first-ribbon + section & {
    display: none;
  }
  @include schemeColor(background-color, base);
}

// Type
// --------------------------------------------------------
.section-header {
  $sectionHeaderPadding: 50px;

  position: relative;
  padding-top: $sectionHeaderPadding;

  &.no-title {
    @each $_, $context in $spectraContexts {
      .scheme-#{$context} + .scheme-#{$context} & {
        padding-top: 0;
      }
    }

    .scheme-none + .scheme-none {
      padding-top: 0;
    }
  }

  // Default bottom padding with paragraph
  padding-bottom: 40px;

  h1, h2, h3, h4, h5, h6 {
    @include headingPrimary;
    // Has a little bit of bottom padding to create more space
    // when there is no paragraph in between
    padding-bottom: 10px;
    color: $neutralOffBlack;

    a {
      color: $neutralOffBlack;
      text-decoration: none;

      &:hover {
        color: $accentSecondary;
        opacity: 1;
        // Override
        // scss-lint:disable DeclarationOrder
        @include schemeColor(color, base);
      }
    }

    .scheme-base &, .scheme-dark & {
      color: $neutralWhite;
    }

    .scheme-medium & {
      @include schemeColor(color, dark);
    }
  }

  // Optional paragraph
  p {
    @include copyPrimary;
    padding-top: 16px;
    color: $neutralGray70;

    .scheme-base &, .scheme-dark & {
      color: $neutralWhite;
    }

    .navigation-two-column & {
      color: $neutralGray40;
    }

    .scheme-medium & {
      @include schemeColor(color, base);
    }
  }

  .arrow-link {
    position: absolute;
    top: $sectionHeaderPadding + 10;
    right: 0;
    color: $neutralOffBlack;
    text-decoration: none;
    letter-spacing: 0.1em;

    &:hover {
      color: $accentSecondary;
      opacity: 1;
      // Override
      // scss-lint:disable DeclarationOrder
      @include schemeColor(color, base);
    }
  }

  @include respond($break60) {
    // Leave space for arrow link
    padding-right: 120px;
  }
}

.section-subheader {
  padding-top: 40px;
  padding-bottom: 34px;

  p {
    @include copySecondary;
    color: $neutralGray70;

    .scheme-medium & {
      @include schemeColor(color, base);
    }
  }
}


// Navigation Lists
// --------------------------------------------------------
.pager {
  ul {
    @include listUnstyled;
    @include clearfix;
  }

  li {
    float: left;
    font-size: 13px;
    background-color: $neutralGray20;

    a {
      text-decoration: none;

      &.active {
        text-decoration: underline;
      }
    }

    a, &.ellipses {
      display: block;
      height: 37px;
      padding: 11px 9px 0;
      color: $accentPrimary;
    }

    &:first-child + li a {
      padding-left: 15px;
    }
  }

  .pager-left, .pager-right {
    padding: 13px 15px 0;
    font-size: 12px;
    color: $neutralWhite;
    background-color: $accentPrimary;

    &.disabled {
      color: $neutralGray70;
      background-color: $neutralGray30;
    }
  }

  .pager-left {
    @include iconPre($arrowLeft);
  }

  .pager-right {
    @include iconPost($arrowRight);
  }
}


// Buttons/Links
// --------------------------------------------------------
// <button>
.close-flush {
  @include buttonUnstyled;
  @include iconPre($x);
  display: block;
  width: 38px;
  padding-top: 35px;
  color: $neutralOffBlack;
  border-top: 3px solid;

  &::before {
    font-size: 13px;
  }
}

.arrow-link {
  @include iconPost($arrowRight);
  position: relative;
  padding-right: 42px;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &::after {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -7px;
    margin-left: 10px;
    font-size: 11px;
    vertical-align: baseline;
    transition: right $duration $timing;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:hover::after {
    right: 0;
  }
}

.arrow-link-left {
  @include iconPre($arrowLeft);
  position: relative;
  padding-left: 42px;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &::before {
    position: absolute;
    top: 50%;
    left: 12px;
    margin-top: -7px;
    font-size: 11px;
    vertical-align: baseline;
    transition: left $duration $timing;
  }

  &:hover {
    opacity: 1;
  }

  &:hover::before {
    left: 0;
  }
}

.arrow-link-up {
  @include iconPre($arrowUp);
  @include labelPrototype;
  position: relative;
  display: inline-block;
  padding-top: 40px;
  font-size: 9px;
  color: $neutralGray70;
  text-decoration: none;

  &::before {
    position: absolute;
    bottom: 23px;
    display: block;
    width: 100%;
    font-size: 18px;
    color: $accentPrimary;
    text-align: center;
    transition: bottom $duration $timing;
  }

  &:hover {
    color: $accentSecondary;
    opacity: 1;

    &::before {
      bottom: 33px;
      color: $accentSecondary;
    }
  }
}

.arrow-link-list a {
  display: block;
  padding-top: 18px;
  padding-bottom: 5px;
  color: $accentPrimary;
  text-decoration: none;
  letter-spacing: 0.1em;
  border-bottom: 1px solid;
  transition: color $duration $timing, border-color $duration $timing;

  &::after {
    top: 26px;
    right: 0;
    color: $accentPrimary;
    transition: color $duration $timing;
  }

  &:hover {
    color: $accentSecondary;
    border-bottom-color: $accentSecondary;
    opacity: 1;

    &::after {
      color: $accentSecondary;
    }
  }
}

.caret-down-link {
  @include labelPrototype;
  @include iconPost($caretDown);
  display: inline-block;
  font-size: 11px;
  color: $accentPrimary;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  &:hover::after {
    transform: translateY(4px);
  }

  &::after {
    margin-top: 3px; // NP
    margin-left: 8px;
    font-size: 7px;
    vertical-align: top;
    transition: transform $duration $timing;
  }
}

.sortable-col {
  cursor: pointer;

  &::after {
    margin-left: 3px;
    font-size: 6.5px;
  }

  &.asc {
    @include iconPost($caretUp);
  }

  &.desc {
    @include iconPost($caretDown);
  }
}



.header-hamburger {
  @include buttonUnstyled;
  @include labelPrototype;
  @include iconPre($hamburger);
  position: absolute;
  left: $padding30;
  font-size: 12px;
  color: $neutralWhite;
  text-decoration: none;

  @include respond($break100) {
    left: $padding100;
  }

  &:hover {
    color: $accentSecondary;
    opacity: 1;
  }

  &::before {
    margin-right: 10px;
    font-size: 11px;
  }

  span {
    display: none;

    @include respond($break60) {
      display: inline;
    }
  }
}

.header-magnify {
  @include buttonUnstyled;
  @include iconPre($magnify);
  position: absolute;
  top: 0;
  right: $padding30;
  font-size: 14px;
  color: $neutralWhite;
  text-decoration: none;

  @include respond($break100) {
    right: $padding100;
  }

  &:hover {
    color: $accentSecondary;
    opacity: 1;
  }
}

.logo-seal {
  padding-top: 30px;

  svg {
    display: inline-block;
    max-width: 183px;
  }
}

// Forms
// --------------------------------------------------------
// Form defaults

// These are form style defaults as used on database pages, however they can be
// pushed into the database-page partial if necessary
// Styled checkbox based on http://wtfforms.com/

.select {
  @include iconPost($caretDown);
  position: relative;
  display: inline-block;
  cursor: pointer;

  // Hide new arrow in IE9, provide standard styling
  .ie9 & {
    select {
      padding: 12px 10px 11px;
    }

    &::after {
      display: none;
    }
  }

  select {
    display: inline-block;
    width: 100%;
    min-height: 40px;
    padding: 12px 37px 11px 19px;
    margin: 0 0 5px;
    font-size: 13px;
    color: $neutralOffBlack;
    background-color: $neutralGray20;
    border: 0;
    border-radius: 0;
    outline: none;
    appearance: none;

    &:focus {
      background-color: $neutralGray30;
    }

    &:focus:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $neutralOffBlack;
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -3px;
    font-size: 6px;
    color: $neutralOffBlack;
    pointer-events: none;
  }
}

.select-label {
  @include labelPrototype;
  font-size: 11px;

  .select + & {
    margin-left: 10px;
  }
}

.checkbox {
  position: relative;
  display: inline-block;
  padding-left: 32px;
  font-size: 12px;
  line-height: 18px;
  color: $neutralGray70;
  cursor: pointer;

  input {
    position: absolute;
    // Relative z-index
    z-index: -1;
    opacity: 0;

    &:checked ~ .toggle-indicator {
      background-color: $accentPrimary;

      &::before {
        opacity: 1;
      }
    }
  }

  .toggle-indicator {
    @include iconPre($check);
    position: absolute;
    left: 0;
    width: 17px;
    height: 17px;
    padding-bottom: 2px;
    padding-left: 1px; // NP
    margin-right: 9px;
    text-align: center;
    user-select: none;
    background-color: $neutralGray30;
    transition: opacity $duration $timing;

    &::before {
      font-size: 8px;
      color: $neutralWhite;
      opacity: 0;
    }

    &.partially-checked {
      @include iconPre($minus);
      background-color: $accentPrimary;

      &::before {
        font-size: 9px;
        color: $neutralWhite;
        opacity: 1;
      }
    }
  }
}

.invisible {
  display: none;
}

.block-tag {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: inline-block;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: $semibold;
  color: $neutralWhite;
  text-transform: uppercase;
  background-color: $accentPrimary;
}

.newsletter-signup {
  padding-bottom: 46px;
  color: $accentTertiary;

  .section-header {
    padding-top: 38px;
    padding-bottom: 2px;

    h3 {
      font-size: 25px;
    }
  }

  .newsletter-link {
    @include schemeColor(color, dark);
    
    max-width: 465px;
    padding: 16px 10px 16px 16px;
    margin: 26px 0 24px;
    border: 1px solid;

    .scheme-base &, .scheme-dark & {
      border: 1px solid $neutralWhite;
    }
  }

  .arrow-link {
    @include schemeColor(color, dark);

    .scheme-base &, .scheme-dark & {
      color: $neutralWhite;
    }
  }

}

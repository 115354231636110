// Variables
//----------

// Colors
// --------------------------------------------------------
// Neutrals
$neutralWhite: #ffffff;
$neutralBlack: #000000;
$neutralOffBlack: #323233;

$translucentWhite: rgba(250, 250, 250, 0.65);

$neutralGray100: #8a8a8a;
$neutralGray90: #4f4f4f;
$neutralGray70: #5c5c5c;
$neutralGray60: #737373;
$neutralGray55: #848484;
$neutralGray50: #969696;
$neutralGray40: #cccccc;
$neutralGray30: #dadada;
$neutralGray20: #f0f0ee;
$neutralGray10: #f7f5ee;

// Accent Colors
$accentPrimary: #003c5b;
$accentPrimaryDark: #001d36;

$accentSecondary: #3fd5e0;

$accentTertiary: #819bae;

// Spectra
// Numbered secondary colorschemes

$spectraContexts: (
  0: 'base',
  1: 'light',
  2: 'medium',
  3: 'dark',
  4: 'gray'
);

// Note that these colors should not to be used on their own,
// but through mixins and loops that set a color scheme based on a parent class
$spectra: (
  s0: (
    base: $accentPrimary,
    light: #efffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s1: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s2: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s3: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s4: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s5: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s6: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s7: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  ),

  s8: (
    base: #006080,
    light: #ffffff,
    medium: #dae4ed,
    dark: #08243b
  )
);

// Typography
// --------------------------------------------------------
$typeReset: 16px;

// Measurements
// --------------------------------------------------------
// Site padding from widest to narrowest
$padding100: 50px;
$padding30: 4.688%;

// Main container width
$containerWidth: 924px + $padding100 * 2;

$baseLineHeight: 1.4;
$verticalSpace: $baseLineHeight * 1em;

// $durationFast: 0.1s;
$duration: 0.2s;
$durationSlow: 0.4s;
$timing: ease;

// Font Weights
// --------------------------------------------------------
$light: 300;
$regular: 400;
$semibold: 600;
// $bold: 700;

// Responsive breakpoints
// --------------------------------------------------------
// $break130: ;
// $break120: ;
// $break110: ;
$break100: $containerWidth;
// $break90: ;
$break80: 840px;
$break70: 760px;
$break60: 665px;
// $break50: ;
$break40: 545px;
$break30: 475px;
// $break20: ;
// $break10: ;

.logo-list {
  @include listUnstyled;
  display: inline-block;
  text-align: center;

  @include respond($break100) {
    margin-left: -7px;
    text-align: left;
  }

  li {
    display: inline-block;
    width: 210px;
    height: 170px;
    padding: 14px;
    margin: 7px;
    font-size: 0;
    vertical-align: top;
    background-color: $neutralWhite;

    a {
      display: inline-block;
      width: calc(100% - 1px);
    }

    img {
      display: inline-block;
      width: calc(100% - 1px);
      vertical-align: middle;
    }
  }

  .ghosty {
    position: relative;
    display: inline-block;
    width: 1px;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
}

// One grid, many configurations
// A wrapper class with a nested .grid class
.summary-grid {
  .grid {
    @include grid(3, 15px);
    padding-bottom: 60px;
    align-items: stretch;

    .col {
      display: flex;
    }
  }

  a,
  .grid-block {
    position: relative;
    display: flex;
    flex-direction: column;
    inline-size: 100%;
    padding: 24px 23px 25px;
    text-decoration: none;
    background-color: $neutralGray20;
    transition: background-color $duration $timing;

    .scheme-base &,
    .scheme-medium &,
    .scheme-dark & {
      @include schemeColor(background-color, light);

      .summary-header {
        @include schemeColor(color, base);
      }
    }

    .scheme-light & {
      @include schemeColor(background-color, medium);
    }
  }

  a {
    &:hover {
      @include schemeColor(background-color, base);
      opacity: 1;

      .summary-header,
      p,
      .arrow-link {
        color: $neutralWhite;
      }

      .hide-on-hover {
        visibility: hidden;
        opacity: 0;
      }

      .show-on-hover {
        visibility: visible;
        opacity: 1;
      }

      // Scheme medium variety
      .scheme-base &,
      .scheme-medium &,
      .scheme-dark & {
        background-color: $neutralWhite;

        // Revert hover colors for this theme only
        .arrow-link {
          color: $neutralOffBlack;
          transform: color $duration $timing;
        }

        p {
          color: $neutralGray70;
        }
      }
    }
  }

  .summary-header {
    margin-bottom: 7px;
    font-size: 15px;
    line-height: 24px;
    color: $neutralOffBlack;

    + p {
      padding-bottom: 34px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  // Text that appears directly below the summary header
  .summary-meta {
    font-size: 15px;
    block-size: 100%;
  }

  // Default Summary paragraph
  p {
    font-size: 13px;
    line-height: 1.331;
    color: $neutralGray70;

    + p {
      margin-top: 10px;
    }
  }

  .arrow-link {
    position: absolute;
    bottom: 23px;
    color: $neutralOffBlack;
  }

  // Elements revealed on hover
  .show-on-hover {
    visibility: hidden;
    opacity: 0;
  }
}

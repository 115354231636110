.lead-primary {
  padding-top: 94px;
  padding-bottom: 90px;
  color: $neutralGray40;
  text-align: center;
  background-color: $accentPrimaryDark;

  header {
    padding-bottom: 24px;

    .label {
      @include labelPrototype;
      padding-bottom: 34px;
      font-size: 12px;
      color: $accentSecondary;
    }

    .title {
      @include fontHead;
      max-width: 784px;
      margin: 0 auto;
      font-size: 40px;
      line-height: 1.225;
      color: $neutralWhite;
    }
  }

  p {
    @include copyPrimary;
    max-width: 635px;
    margin: 0 auto;
  }

  .arrow-link {
    display: inline-block;
    margin-top: 48px;
    color: $neutralWhite;

    &:hover {
      color: $accentSecondary;
    }
  }
}

.lead-secondary {
  padding-top: 50px;
  padding-bottom: 63px;
  text-align: center;
  background-color: $neutralGray20;

  .hero + & {
    padding-top: 116px;
    margin-top: -66px;
  }

  header + p {
    margin-top: 1.4em;
  }

  .label {
    @include labelPrimary;
    @include schemeColor(color, base);
    padding-bottom: 30px;
  }

  .title {
    @include fontHead;
    max-width: 723px;
    margin: 0 auto;
    font-size: 32px;
    line-height: 1.281;
    color: $neutralOffBlack;
  }
}

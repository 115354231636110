.blurb-grid {
  @include listUnstyled;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(287px, 1fr) );
  gap: 30px;
  margin-block-end: 90px;

  > .col > a {
    @include schemeColor(border-color, base);
    transition: border-color $duration $timing;

    display: flex;
    flex-direction: column;
    gap: 17px;

    min-inline-size: 200px;

    border-block-start: 3px solid $neutralOffBlack;
    padding-block-start: 16px;

    text-decoration: none;


    > .source {
      @include labelPrototype;
      font-size: 11px;
      color: $neutralOffBlack;

      transition: color $duration $timing;
    }

    > .title {
      font-size: 1rem;
      font-weight: $regular;
      line-height: 1.5;
      color: $neutralGray70;

      transition: color $duration $timing;
      font-size: 1rem;
    }

    &:hover {
      border-color: $accentSecondary;
      opacity: 1;
      // Override
      // scss-lint:disable DeclarationOrder
      @include schemeColor(border-color, base);

      .source,
      .title {
        color: $accentSecondary;
        // Override
        // scss-lint:disable DeclarationOrder
        @include schemeColor(color, base);
      }
    }
  }
  }
.site-header {
  padding: 23px 0 27px;
  text-align: center;
  background-color: $accentPrimary;

  // Slight home variant
  &.home {
    @include respond($break30) {
      padding-top: 34px;
      padding-bottom: 34px;
    }
  }

  .container {
    position: relative;
  }

  // <a>
  .logo-type {
    display: inline-block;

    svg {
      display: inline-block;
      width: 100%;
      max-width: 208px;
      height: auto;
    }

    &--interior {
      svg {
        display: inline-block;
        width: 42.11vw;
        max-width: 200px;
        margin-top: -1px;

        @include respond($break30) {
          max-width: 348px;
          margin-top: -2px;
        }
      }
    }
  }

  .header-hamburger, .header-magnify {
    top: 50%;
    transform: translateY(-50%);
  }
}

.content-grid-two {
  @include grid(2, 65px, 0);
  padding-bottom: 67px;
}

.content-grid-newspaper-layout {
  columns: 1;
  column-gap: 0;
  padding: 0 0 60px;

  section:first-child > .container > & {
    padding: 0 10.145% 60px;

    @include respond($break70) {
      padding: 0 50px 60px;
    }
  }

  // Override
  // scss-lint:disable DeclarationOrder
  @include respond($break70) {
    columns: 2;
    column-gap: 80px;
  }
}

// Grids
// -----
@use "sass:math";

@mixin grid($count, $padding, $rowPadding: $padding) {
  display: grid;

  grid-template-columns: repeat(1, 1fr);
  @if ($count > 1) {
    @include respond($break40) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include respond($break70) {
    grid-template-columns: repeat($count, 1fr);
  }
  gap: $padding;
  row-gap: $rowPadding;
  overflow: hidden;
  font-size: 0;

  // If used on a <ul>
  @include listUnstyled;

  .col {
    font-size: $typeReset;
    align-items: stretch;
  }
}

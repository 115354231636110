.callout-grid {
  .row {
    padding: 87px 0 68px;

    @include respond($break70) {
      display: table;
      width: calc(100% + 5vw);
      height: 100%;
      margin-left: -5vw;
    }

    + .row {
      padding-top: 0;
    }
  }


  .col {
    @include respond($break70) {
      display: table-cell;
      width: 50%;
      height: 100%;
      padding-left: 5vw;
      vertical-align: top;
    }

    + .col {
      margin-top: 66px;

      @include respond($break70) {
        margin-top: 0;
      }
    }
  }

  .link {
    @include schemeColor(border-color, base);
    position: relative;
    display: block;
    height: 100%;
    padding: 30px 31px;
    color: $neutralOffBlack;
    text-decoration: none;
    border: 10px solid;

    &:hover {
      opacity: 1;
    }

    @include respond($break70) {
      // To keep strange full height table-cell link
      box-sizing: content-box;
    }

    .label {
      @include labelPrototype;
      @include schemeColor(color, base);
      display: block;
      padding-bottom: 15px;
      font-size: 14px;
    }

    .link-heading {
      @include fontHead;
      padding-bottom: 70px;
      font-size: 32px;
      line-height: 1.238;
      color: $neutralOffBlack;
    }

    .arrow-link {
      position: absolute;
      bottom: 50px;
    }
  }

  .image {
    .image-heading {
      @include fontHead;
      font-size: 32px;
      line-height: 1.238;
      color: $neutralWhite;
      opacity: 1;
    }

    .arrow-link {
      position: absolute;
      bottom: 50px;
      left: 31px;
      color: $neutralWhite;
    }

    figure {
      position: relative;
      width: 100%;
      height: 360px;
      background-position: 50% 50%;
      background-size: cover;

      figcaption {
        position: absolute;
        top: 0;
        width: 100%;
        padding: 35px 31px;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        background-color: $neutralOffBlack;
        opacity: 0.5;
      }
    }
  }

  .video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    &:hover {
      opacity: 1;
    }

    figure {
      position: relative;
      width: 100%;
      height: 360px;
      background-position: 50% 50%;
      background-size: cover;

      @include respond($break40) {
        height: 45vw;
      }

      @include respond($break70) {
        height: 100%;
        min-height: 35.156vw;
      }

      @include respond($break100) {
        min-height: 360px;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        content: '';
        background-color: rgba($neutralBlack, 0.2);
        transition: background-color $duration $timing;
      }

      figcaption {
        position: absolute;
        bottom: 0;
        width: 100%;
      }

      .play-button {
        // Content-less element with border and pseudo-triangle
        position: absolute;
        top: 40%;
        left: 50%;
        width: 1em;
        height: 1em;
        margin-top: -0.5em;
        margin-left: -0.5em;
        // Using font size for relative size adjustment
        font-size: 100px;
        border: 2px solid $neutralWhite;
        border-radius: 100%;

        @include respond($break80) {
          font-size: 140px;
        }

        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          display: block;
          width: 0;
          height: 0;
          margin-top: -0.196em;
          margin-left: -0.121em;
          content: '';
          border-color: transparent transparent transparent $neutralWhite;
          border-style: solid;
          border-width: 0.196em 0 0.196em 0.321em;
        }
      }

      .video-label {
        @include captionPrimary;
        position: absolute;
        right: 13px;
        bottom: calc(100% + 10px);
        display: block;
        margin-top: -10px;
        color: $neutralWhite;
      }

      .video-title {
        @include schemeColor(background-color, base);
        @include labelPrototype;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 19px 20px 21px;
        font-size: 12px;
        line-height: 17px;
        color: $neutralWhite;
      }
    }
  }
}
